<template>
	<edit-template class="page-wrapper white">
		
    <div class="page-info">
      <div class="form-padding">
        <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140rem" label-position="left" @submit.native.prevent="">
          <el-form-item prop="student_id" label="学号">
            <el-select v-model="formData.student_id" filterable="" remote="" reserve-keyword="" placeholder="请输入学生完整学号" @change="handleIdChange" :remote-method="remoteMethod" :loading="loading">
              <el-option v-for="item in studentsList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="pad_uniqueId" label="新平板设备ID">
            <el-input type="textarea" v-model="formData.pad_uniqueId" maxlength="200" show-word-limit="" :autosize="autosize" placeholder="请输入新平板设备ID"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="新密码">
            <el-input v-model="formData.password" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="cause" label="更换设备原因">
            <el-input type="textarea" v-model="formData.cause" maxlength="200" show-word-limit="" :autosize="autosize" placeholder="请输入更换设备原因"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <showview :visible.sync="isShowDialog" title="查看平板设备ID操作说明">
        <el-image :src="showInfo" v-if="showInfo"></el-image>
        <div class="no-info" v-loading="isShowLoading" v-else=""></div>
      </showview>
    </div>
    <template slot="footer">
      <el-button type="primary" @click="submitForm('ruleForm')">新增</el-button>
      <el-button type="info" @click="resetForm('ruleForm')">取消</el-button>
      <el-button @click="handleShowDialog" style="margin-left: 40rem">查看平板设备ID</el-button>
    </template>
  
</edit-template>
</template>
<script>
import { getApi2, postApi2 } from '@/api'
import ShowView from './components/ShowView.vue'
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: { ShowView },
  data() {
    return {
      autosize: {
        minRows: 5,
        maxRows: 8
      },
      formData: {
        student_no: '',
        student_id: '',
        password: '',
        pad_uniqueId: '',
        cause: ''
      },
      rules: {
        student_id: [{ required: true, message: '请选择学生', trigger: 'change' }],
        pad_uniqueId: [{ required: true, message: '请输入新平板设备ID', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^[\w]{6,20}$/, message: '请输入6～30个字符，支持数字、字母、区分字母大小写，不支持特殊符号', trigger: 'blur' }
        ],
        cause: [{ required: true, message: '请输入更换设备原因', trigger: 'blur' }]
      },
      loading: false,
      studentsList: [],
      isShowDialog: false,
      isShowLoading: false,
      showInfo: ''
    }
  },
  methods: {
    handleIdChange(val) {
      const obj = this.studentsList.find(item => item.value === val)
      this.formData.student_id = obj.value
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.onSearch(query)
      } else {
        this.studentsList = []
      }
    },
    onSearch(student_no) {
      getApi2('/operation/student-lock/search-student', { student_no })
        .then(res => {
          this.studentsList = res.map(item => ({
            value: item.student_id,
            label: `${item.student_name}(${item.student_no})`
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getShow() {
      this.isShowLoading = true
      getApi2('/operation/student-lock/lock-show')
        .then(res => {
          this.showInfo = res.lock_show
        })
        .finally(() => {
          this.isShowLoading = false
        })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let { student_no, ...data } = this.formData
          postApi2('/operation/student-lock/add', data).then(() => {
            this.$router.back()
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields()
      let that = this
      this.$confirm('确定取消新增？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$router.back()
      })
    },
    handleShowDialog() {
      this.isShowDialog = true
      if (!this.showInfo) {
        this.getShow()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.no-info {
  height: 400rem;
}
::v-deep {
  .el-input {
    width: 400rem !important;
  }
}
</style>
