<template>
  <el-dialog :title="title" :close-on-click-modal="true" :visible="visible" @close="handleCloseDialog">
    <slot></slot>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    handleCloseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
    }
  }
}
</script>
